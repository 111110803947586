import type { NavigationGuardWithThis } from 'vue-router';

import { LOGIN_PATH } from '@/router/router';
import { useUserLoginStore } from '@/stores';

const autorizationNavigationGuard: NavigationGuardWithThis<undefined> = async (to, _, next) => {
    if (!to.meta.isPublic) {
        const userLoginStore = useUserLoginStore();

        const isAuthenticated = userLoginStore.isLoggedIn;

        if (isAuthenticated) {
            next(); // ToDo: check authorization
        } else {
            next({ path: LOGIN_PATH });
        }
    } else {
        next();
    }
};

export { autorizationNavigationGuard };
