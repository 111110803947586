import { defineStore } from 'pinia';

import { AuthenticationTokensDto } from '@/apiclient/api.generated.clients';

const TOKENS_STORAGE_KEY = 'tckt-tokens';

function getTokensFromLocalStorage(): AuthenticationTokensDto | undefined {
    const tokens = localStorage.getItem(TOKENS_STORAGE_KEY);
    return tokens ? AuthenticationTokensDto.fromJS(JSON.parse(tokens)) : undefined;
}

function saveTokensToLocalStorage(tokens: AuthenticationTokensDto) {
    localStorage.setItem(TOKENS_STORAGE_KEY, JSON.stringify(tokens));
}

export const useUserLoginStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize the state from the local storage to enable the user to stay logged in between page refreshes
        savedTokens: getTokensFromLocalStorage(),
    }),
    actions: {
        setTokens(tokens: AuthenticationTokensDto) {
            this.savedTokens = Object.assign({}, tokens);
            saveTokensToLocalStorage(tokens);
        },
        clearTokens() {
            this.savedTokens = undefined;
        },
    },
    getters: {
        accessToken: state => {
            return state.savedTokens?.accessToken;
        },
        tokens: state => {
            return state.savedTokens;
        },
        isLoggedIn: state => {
            return !!state.savedTokens;
        },
    },
});

export type UserLoginStoreType = ReturnType<typeof useUserLoginStore>;
