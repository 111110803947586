/* istanbul ignore file -- @preserve */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { router } from '@/router/router';
import App from '@/App.vue';
import { initializeVeeValidate } from '@/validation/initializeVeeValidate';

initializeVeeValidate();

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(App).use(pinia).use(router).mount('#app');
