/* istanbul ignore file -- @preserve */
import { configure, defineRule } from 'vee-validate';
import { required, email, min, max, confirmed, numeric } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

export function initializeVeeValidate() {
    defineRule('required', required);
    defineRule('email', email);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('confirmed', confirmed);
    defineRule('numeric', numeric);

    // TODO: configure Locale in the future.
    configure({
        generateMessage: localize({
            en: {
                messages: {
                    required: 'This field is required',
                    email: 'This field must be a valid email',
                    min: 'This field must be at least 0:{min} characters',
                    max: 'This field must be at most 0:{max} characters',
                    confirmed: 'The confirmation does not match',
                    numeric: 'This field must be a number',
                },
                fields: {
                    password: {
                        required: 'Password is required',
                    },
                },
            },
        }),
    });
}
