import type { NavigationGuardWithThis } from 'vue-router';
import { MENU_ROUTES } from '@/router/router';

async function getLandingPagePath() {
    return MENU_ROUTES.EXHIBITORS;
}

const landingPageRedirectionGuard: NavigationGuardWithThis<undefined> = async (to, _, next) => {
    if (to.path === '/') {
        const landingPagePath = await getLandingPagePath();
        next(landingPagePath);
        return;
    }
    next();
};

export { landingPageRedirectionGuard };
